import { Box, Flex } from '@chakra-ui/react'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { useUser } from 'src/context/userContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { useConfetti } from 'src/hooks/useConfetti'
import { usePlaylistStore } from 'src/hooks/usePlaylist'
import { useWindowSize } from 'src/hooks/useWindowSize'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { makeMarkContent } from 'src/modules/cms/factories/makeMarkContent'
import { ContentAction } from 'src/modules/cms/use_cases/MarkContentUseCase'
import { makeGetUserCertificates } from 'src/modules/iam/factory/makeGetUserCertificates'
import { CertificateDto } from 'src/modules/iam/use-case/GetUserCertificatesUseCase'
import { LearningItemProgress } from 'src/types/LearningItemProgress'
import { useModal } from '../../hooks/useModal'
import CourseModal from '../CourseModal'
import LearningItemModal from '../LearningItemModal'
import { HorizontalLearningCardCheck } from './HorizontalLearningCardCheck'
import { HorizontalLearningCardContent } from './HorizontalLearningCardContent'
interface Props {
  content: Content
  showImage?: boolean
  style?: React.CSSProperties
  showMarkButtons?: boolean
  showCheckButtons?: boolean
  showCustomPlaylistButtons?: boolean
  onUpdateLearningItem?: (content: Content) => void
  onKnowMoreClick?: (content: Content) => void
  playlist?: NewPlaylist
  checkIndex?: number
  showHeaderInfo?: boolean
  showMainIconButton?: boolean
  showProficiency?: boolean
  showAddItemButtons?: boolean
  showRemoveButton?: boolean
  showSource?: boolean
  moduleName?: string
  moduleId?: string
}

export const HorizontalLearningItemCard: React.FC<Props> = ({
  content,
  playlist,
  showImage,
  style,
  showMarkButtons = false,
  onUpdateLearningItem,
  onKnowMoreClick,
  checkIndex,
  showCheckButtons = false,
  showHeaderInfo = true,
  showMainIconButton = true,
  showProficiency = true,
  showAddItemButtons = false,
  showRemoveButton = false,
  showSource = true,
  moduleName,
  moduleId,
  showCustomPlaylistButtons,
}: Props) => {
  const [nContent, setContent] = useState<Content>(content)
  const { updateCertificate } = usePlaylistStore()
  const { companyPlaylistId } = useParams<{ companyPlaylistId: string }>()
  const location = useLocation()
  const { EventsImplementation, track } = useMixpanel()
  const getCertificates = makeGetUserCertificates()
  const { isOpen, toggleModal } = useModal(false)
  const { isOpen: isCompleteOpen, toggleModal: toggleCompleteModal } = useModal(false)
  const { showConfetti } = useConfetti()
  const { user, getUser } = useUser()
  const markContent = makeMarkContent()

  const { width } = useWindowSize()

  const isMobile = useMemo(() => width && width < 992, [width])

  const playlistId = playlist?.id
  const moduleIdLocal =
    moduleId ??
    playlist?.moduleList?.items?.find((m) => m.contentList?.items?.find((c) => c.id === content.id))?.id ??
    ''
  const handleMarkAsDone = async () => {
    await markContent.execute({
      id: content.id,
      action: ContentAction.DONE,
      moduleId: moduleIdLocal,
      playlistId,
    })
    getUser()
    onMarkAsDone()
  }

  useEffect(() => {
    setContent(content)
  }, [content])

  const onMarkAsDone = () => {
    const contentCopy = { ...content }
    contentCopy.progress = LearningItemProgress.DONE

    showConfetti()
    if (onUpdateLearningItem) onUpdateLearningItem(contentCopy)
    getCertificates.execute({ playlistId: playlist?.id }).then((cert) => {
      const findedCertificate = cert?.items?.find((item) => item.playlistId === playlist?.id)
      updateCertificate(findedCertificate ?? ({} as CertificateDto))
    })
    setContent(contentCopy)
    track(EventsImplementation.MARK_AS_DONE(content.title, location.pathname, 'Card'))
  }

  const onMarkAsDoing = () => {
    const contentCopy = { ...content }
    contentCopy.progress = LearningItemProgress.DOING
    setContent(contentCopy)
    if (onUpdateLearningItem) onUpdateLearningItem(contentCopy)
  }

  const onMarkAsUndone = async () => {
    try {
      const contentCopy = { ...content }
      contentCopy.progress = LearningItemProgress.DOING
      if (onUpdateLearningItem) onUpdateLearningItem(contentCopy)
      await markContent.execute({
        id: content.id,
        action: ContentAction.UNDONE,
        moduleId: moduleIdLocal,
        playlistId,
      })
      getUser()
      setContent(contentCopy)
      track(EventsImplementation.MARK_AS_UNDONE(content.title, location.pathname, 'Card'))
    } catch (error) {
      const contentCopy = { ...content }
      contentCopy.progress = LearningItemProgress.DONE
      setContent(contentCopy)
      if (onUpdateLearningItem) onUpdateLearningItem(contentCopy)
    }
  }

  if (!user) return null

  return (
    <Flex alignItems='center' direction={{ base: 'column', lg: 'row' }}>
      {showCheckButtons && !isMobile ? (
        <HorizontalLearningCardCheck
          content={nContent}
          handleMarkAsDone={handleMarkAsDone}
          index={checkIndex!}
          playlist={playlist}
          toggleCompleteModal={toggleCompleteModal}
          toggleModal={toggleModal}
        />
      ) : null}
      <Box width='100%' id={content.id}>
        <HorizontalLearningCardContent
          content={nContent}
          isCompleteOpen={isCompleteOpen}
          onMarkAsUndone={onMarkAsUndone}
          onMarkAsDoing={onMarkAsDoing}
          onMarkAsDone={onMarkAsDone}
          onKnowMoreClick={onKnowMoreClick}
          toggleCompleteModal={toggleCompleteModal}
          toggleModal={toggleModal}
          showMarkButtons={showMarkButtons}
          showImage={showImage}
          style={style}
          setLearningItem={setContent}
          showHeaderInfo={showHeaderInfo}
          showMainIconButton={showMainIconButton}
          showProficiency={showProficiency}
          playlist={playlist}
          moduleName={moduleName}
          moduleId={moduleId}
          showAddItemButtons={showAddItemButtons}
          showRemoveButton={showRemoveButton}
          showCustomPlaylistButtons={showCustomPlaylistButtons}
          showSource={showSource}
          isCompanyPlaylist={!!companyPlaylistId}
        />
      </Box>
      {[ContentType.COURSE, 'OldCourse', 'CourseDto'].includes(nContent.type) ? (
        <CourseModal initialCourse={nContent} onClose={toggleModal} isOpen={isOpen} setCourse={setContent} />
      ) : (
        <LearningItemModal
          initialLearningItem={nContent}
          onClose={toggleModal}
          isOpen={isOpen}
          setLearningItem={setContent}
          playlistId={playlistId}
          moduleId={moduleIdLocal}
        />
      )}
    </Flex>
  )
}
