import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useUser } from 'src/context/userContext'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import useRegisterUserEvent from 'src/hooks/useRegisterUserEvent'
import Content from 'src/modules/cms/domain/Content'
import { makeMarkContent } from 'src/modules/cms/factories/makeMarkContent'
import { ContentAction } from 'src/modules/cms/use_cases/MarkContentUseCase'
import FavoriteIconSvg from './FavoriteIconSvg'

interface FavoriteIconProps {
  content: Content
  onFavorite?: (item: Content) => void
  variant?: string
  height?: string
  iconVariant?: 'default' | 'modal'
  isDrawer?: boolean
}

const FavoriteIcon: React.FC<FavoriteIconProps> = ({
  content,
  variant = 'iconButtonClear',
  onFavorite,
  iconVariant = 'default',
  height = '48px',
  isDrawer = false,
}: FavoriteIconProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { getUser } = useUser()
  const { EventsImplementation, track } = useMixpanel()
  const [showUndo, setShowUndo] = useState(false)
  const registerUserEvent = useRegisterUserEvent()
  const markContent = makeMarkContent()
  const [loading, setLoading] = useState(false)

  const getAccessedOn = (pathname: string) => {
    const formattedPath = pathname.split('/')[1]

    const accessedOn: { [x: string]: string } = {
      home: 'Modal',
      favorites: 'Favorites Page',
      playlist: 'Playlist Page',
      categories: 'Categories Page',
      learningItem: 'Learning Item Page',
    }

    return accessedOn[formattedPath]
  }

  const onFavoriteClick = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    const source = location.pathname

    setLoading(true)
    if (content.favorited) {
      track(
        EventsImplementation.DELETE_FAVORITE_LEARNING_ITEM(
          source,
          content.title,
          content?.partner?.name,
          getAccessedOn(source),
          content.type,
        ),
      )
      await markContent.execute({ id: content.id, action: ContentAction.UNFAVORITED })
      getUser()
    } else {
      track(
        EventsImplementation.ADD_FAVORITE_LEARNING_ITEM(
          source,
          content.title,
          content?.partner?.name,
          getAccessedOn(source),
          content.type,
        ),
      )
      await markContent.execute({ id: content.id, action: ContentAction.FAVORITED })
      getUser()
      if (content.skills)
        registerUserEvent(
          'save-favorite',
          content.skills.map((item) => item.name),
        )
    }

    if (onFavorite) {
      onFavorite({ ...content, favorited: !content.favorited })
    }

    if (showUndo) {
      setShowUndo(false)
    } else {
      setShowUndo(true)
      setTimeout(() => setShowUndo(false), 5000)
    }

    setLoading(false)
  }

  if (!content) return null

  return (
    <>
      <Popover trigger='hover' placement='top'>
        <PopoverContent
          borderColor='gray.200'
          borderRadius='20px'
          width={'fit-content'}
          border='none'
          zIndex={'popover'}
        >
          <PopoverArrow />
          <PopoverBody textAlign={'center'}>
            <Text fontSize={{ base: '.75rem', md: '1rem' }} color='gray.800' fontWeight={700}>
              {content?.favorited ? t('common.card.removeFavorite') : t('common.card.favorite')}
            </Text>
          </PopoverBody>
        </PopoverContent>

        <PopoverTrigger>
          <Button
            onClick={isDrawer ? () => null : onFavoriteClick}
            variant={variant}
            height={height}
            w={{ base: '100%', lg: 'auto' }}
            flexGrow={{ base: 1, lg: 0 }}
            padding={{ base: '12px', lg: '0px' }}
            display='flex'
            alignItems='center'
            justifyContent='center'
            data-testid='favorite-icon-button'
            isLoading={loading}
          >
            <FavoriteIconSvg variant={iconVariant} content={content} />
            <Text fontSize={{ base: '.75rem', md: '.875rem', lg: '1rem' }} display={{ base: 'flex', lg: 'none' }}>
              {content?.favorited ? t('common.card.removeFavorite') : t('common.card.addFavorite')}
            </Text>
          </Button>
        </PopoverTrigger>
      </Popover>

      <Portal>
        {showUndo ? (
          <Flex
            position='fixed'
            width='100%'
            bottom='15px'
            left='0'
            alignItems='center'
            justifyContent='center'
            zIndex='1004'
          >
            <Flex
              backgroundColor='blue.400'
              padding='15px 28px'
              borderRadius='15px'
              filter='drop-shadow(0px 25.7042px 38.5563px rgba(0, 0, 0, 0.26)) drop-shadow(0px 3.21302px 9.63907px rgba(0, 0, 0, 0.04)) drop-shadow(0px 0px 1.60651px rgba(0, 0, 0, 0.04))'
            >
              {content.favorited ? (
                <Text color='white' fontSize='14px' fontWeight='600'>
                  {t('common.card.addedTo')}{' '}
                  <Link to='/favorites'>
                    <u style={{ cursor: 'pointer' }}>
                      <i>{t('common.card.favoritesList')}</i>
                    </u>
                  </Link>
                </Text>
              ) : (
                <Text color='white' fontSize='14px' fontWeight='600'>
                  {t('common.card.removedFromFavorites')}{' '}
                  <u onClick={onFavoriteClick} style={{ cursor: 'pointer' }}>
                    <i>{t('common.card.undo')}</i>
                  </u>
                </Text>
              )}
            </Flex>
          </Flex>
        ) : null}
      </Portal>
    </>
  )
}

export default FavoriteIcon
