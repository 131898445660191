import { Flex, Link, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { CertificateDto } from 'src/modules/iam/use-case/GetUserCertificatesUseCase'

interface ModuleCertificateProps {
  moduleTitle: string
  certificate: CertificateDto
}

export function ModuleCertificate({ moduleTitle, certificate }: ModuleCertificateProps) {
  const [t] = useTranslation()
  
  return (
    <Flex gap='16px' w='100%' bg='#F7F9FA' borderRadius='8px' py='16px' px='12px' align='center' justify='center'>
      <Text fontWeight='700' fontSize='1rem' color='#313E4C'>
        {`${t('playlist.moduleCertificateCongrats')} ${moduleTitle}`}
      </Text>
      <Link href={certificate.url} isExternal>
        <Text textDecoration='underline' fontWeight='500' fontSize='1rem' color='#313E4C'>
          {t('playlist.moduleCertificateCongratsLinkTitle')}
        </Text>
      </Link>
    </Flex>
  )
}
