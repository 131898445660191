import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Team } from 'src/types/Team'
import { isValidEmail } from '../../helpers/account'
import Access from './components/Access'
import { Employee } from './components/ModalUpdateEmployees/components/RemoveEmployees'
import Teams from './components/Teams'
export const homeTabItems = [<Access />, <Teams />]

export const useValidateRemoveEmployees = (listEmployees: Employee[]) => {
  const [t] = useTranslation()
  const [errors, setErrors] = useState<Array<string>>([])

  useEffect(() => {
    setErrors([]) // clean
    if (!listEmployees.length) return
    listEmployees.forEach((employee, index) => {
      const line = index + 2 // skip header and index 0
      // Email
      if (!isValidEmail(employee.email) && !employee.companyIdentifier) {
        setErrors((errors) => [
          ...errors,
          t('admin.home.remove-employees.errors.invalid-email', {
            line,
          }),
        ])
      }
    })
  }, [listEmployees, t])

  return errors
}

export const orderTeams = (teams: Team[], order: string): Team[] => {
  return [...teams].sort((a, b) => {
    const nameA = a.name.toUpperCase()
    const nameB = b.name.toUpperCase()

    if (order === 'NAME_A_Z') {
      return nameA < nameB ? -1 : nameA < nameB ? 1 : 0
    }

    if (order === 'NAME_Z_A') {
      return nameA > nameB ? -1 : nameA > nameB ? 1 : 0
    }

    return 0
  })
}

export const getTranslatedContent = <D,>(language = '', data?: D[], predicate?: (data: D) => unknown) => {
  return data?.find((d) => predicate?.(d) === language)
}
