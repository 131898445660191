import { Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
/* @ts-ignore */
import SemiCircleProgressBar from 'react-progressbar-semicircle'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { getLearningItemsAnalytics } from './header.service'
import { useMemo } from 'react'
import { useUser } from 'src/context/userContext'
import { getModuleTitle } from '../CustomPlaylistModules/modules.services'

interface Props {
  playlist: NewPlaylist
}

const HeaderAnalytics: React.FC<Props> = ({ playlist }: Props) => {
  const [t] = useTranslation()
  const { user } = useUser()

  const moduleTitle = useMemo(() => {
    const modules = (playlist.moduleList?.items?.filter((item) => item?.contentList?.items?.length! > 0) ?? [])

    return getModuleTitle(modules, user?.language)
  }, [playlist, user])

  const { donePercentage } = getLearningItemsAnalytics(playlist)

  return (
    <Flex flexDirection='column'>
      <Flex
        alignItems='center'
        justifyContent='center'
        borderRadius='16px'
        w='100%'
        maxW={{ base: '376px' }}
        background='#F7F9FA'
        marginBottom='21px'
        paddingY='19px'
        paddingLeft='19px'
        paddingRight='30px'
        boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
      >
        <SemiCircleProgressBar
          stroke='#13CE66'
          strokeWidth={13}
          percentage={donePercentage ? donePercentage : 0}
          diameter={82}
        />
        <Flex flexDirection='column' marginLeft='8px' fontSize='18px'>
          <Text color='#009639' fontWeight='700'>
            {donePercentage ? donePercentage : 0}%
          </Text>
          <Text fontWeight='600'>{t('home.Custom Playlist.this playlist has already been completed')}</Text>
        </Flex>
      </Flex>

      <Flex
        alignItems='center'
        justifyContent='center'
        borderRadius='16px'
        w='100%'
        maxW={{ base: '376px' }}
        background='#F7F9FA'
        boxShadow='0px 1px 6px rgba(0, 0, 0, 0.1)'
        paddingY='19px'
        paddingLeft='39px'
        paddingRight='30px'
      >
        <img src={'/assets/images/personalized-target.svg'} alt='' />
        <Flex flexDirection='column' marginLeft='26px' fontSize='18px'>
          <Text fontWeight='600'>
            {t('home.Custom Playlist.Focus on the development of')}{' '}
            <b style={{ color: '#009639', fontWeight: 700 }}>{moduleTitle}</b>
          </Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default HeaderAnalytics
