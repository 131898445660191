import { Button, Icon, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { HiOutlineArrowsExpand } from 'react-icons/hi'

interface Props {
  useShowMoreIconInstead: boolean
  handleOnClick: () => void
}

export const KnowMoreButton: React.FC<Props> = ({ useShowMoreIconInstead, handleOnClick }: Props) => {
  const { t } = useTranslation()
  return (
    <Button
      h='48px'
      variant={useShowMoreIconInstead ? 'iconButtonClear' : 'startCourse'}
      border='2px solid #E5E5E5'
      paddingX='20px'
      onClick={handleOnClick}
      w='auto'
      flexGrow={{ base: 1, lg: 0 }}
    >
      {useShowMoreIconInstead ? (
        <Icon w='30px' h='23px' as={HiOutlineArrowsExpand} />
      ) : (
        <Text fontSize={{ base: '.75rem', md: '1rem' }}>{t('playlist.Know More')}</Text>
      )}
    </Button>
  )
}
