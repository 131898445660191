import { chakra, shouldForwardProp } from '@chakra-ui/react'
import { isValidMotionProp, motion } from 'framer-motion'

/**
 * Create an animated chakra component with motion from 'framer-motion'
 * @param {keyof typeof motion} component - A string that represents component to be created
 * @returns the animated chakra component
 */
export function createChakraAnimatedComponent(component: any) {
  const s:any = (motion as any)[component] as any
  return chakra(s, {
    shouldForwardProp: (prop) => isValidMotionProp(prop) || shouldForwardProp(prop),
  })
}
