import { Button, Icon, Spinner, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoCheckmark } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { useCustomPlaylist } from 'src/context/CustomPlaylist/CustomPlaylistContext'
import { useUser } from 'src/context/userContext'
import { getContentData } from 'src/helpers/contentInfo'
import { getLearningItemsAnalytics } from 'src/modules/cms/components/CustomPlaylist/CustomPlaylistHeader/header.service'
import Content from 'src/modules/cms/domain/Content'
import { ContentType } from 'src/modules/cms/domain/LearningItem'
import NewPlaylist from 'src/modules/cms/domain/Playlist'
import { makeMarkContent } from 'src/modules/cms/factories/makeMarkContent'
import { ContentAction } from 'src/modules/cms/use_cases/MarkContentUseCase'
import { Icons8CircleCloseIcon } from 'src/theme/Icons'
import { User } from 'src/types/User'

interface Props {
  playlist?: NewPlaylist
  content: Content
  isContentDone: boolean
  toggleCompleteModal: () => void
  onMarkAsUndone: () => void
  onMarkAsDone: (userInfo: User | undefined) => void
  openEvaluateModal: () => void
}

export interface ReturnMarkAsDone {
  markContentAsDone: {
    url: string
    credentialId: string
    issueDate: string
  }
}

export const MarkButtons: React.FC<Props> = ({
  playlist,
  content,
  isContentDone,
  toggleCompleteModal,
  onMarkAsUndone,
  onMarkAsDone,
  openEvaluateModal,
}: Props) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { donePercentage } = getLearningItemsAnalytics(playlist!)
  const { setIsEvaluationOpen, setEvaluationSource } = useCustomPlaylist()
  const markContent = makeMarkContent()
  const contentData = getContentData(content?.type, t)
  const isCourse = [ContentType.COURSE, 'OldCourse', 'CourseDto'].includes(content.type)
  const { getUser } = useUser()
  const [isLoading, setIsLoading] = useState(false)

  const moduleId = playlist?.moduleList?.items?.find((m) => m.contentList?.items?.find((c) => c.id === content.id))?.id

  const handleMarkAsDone = async () => {
    if (isLoading) return

    setIsLoading(true)
    await markContent.execute({
      id: content.id,
      moduleId: moduleId ?? '',
      playlistId: playlist?.id,
      action: ContentAction.DONE,
    })
    getUser()

    setIsLoading(false)

    onMarkAsDone(undefined)

    if (pathname === '/customPlaylist') {
      const allContents = playlist?.moduleList?.items?.flatMap((item) => item.contentList?.items) || []
      const contentsFinisheds = allContents.filter((item) => item?.progress === 'DONE')
      const lastContentBefore100Percent = allContents
        .slice()
        .reverse()
        .find((item) => item?.progress === 'IN_PROGRESS' && donePercentage <= 100)
      const contentBefore50Percent = allContents.find(
        (item) => item?.progress === 'IN_PROGRESS' && donePercentage <= 50,
      )

      if (contentsFinisheds.length === 0) {
        setEvaluationSource('first_content_finished')
        setIsEvaluationOpen(true)
      } else if (lastContentBefore100Percent) {
        setEvaluationSource('playlist_finished')
        setIsEvaluationOpen(true)
      } else if (contentBefore50Percent) {
        setEvaluationSource('playlist_half_finished')
        setIsEvaluationOpen(true)
      } else {
        openEvaluateModal()
      }
    } else {
      openEvaluateModal()
    }
  }

  return (
    <>
      {!isContentDone ? (
        <Button
          h='48px'
          variant='startCourse'
          border='2px solid #E5E5E5'
          paddingX='12px'
          w={{ base: 'auto', lg: 'auto' }}
          flexGrow={{ base: 1, lg: 0 }}
          display='flex'
          alignItems='center'
          justifyContent='flex-start'
          onClick={(e) => {
            e.stopPropagation()
            if (isCourse) {
              toggleCompleteModal()
            } else {
              handleMarkAsDone()
            }
          }}
        >
          {isLoading ? (
            <Spinner speed='0.65s' mr='9px' />
          ) : (
            <Icon as={IoCheckmark} w='24px' h='24px' marginRight='9px' />
          )}
          <Text fontSize={{ base: '.75rem', md: '1rem' }}>{contentData?.markAsComplete}</Text>
        </Button>
      ) : null}

      {isContentDone ? (
        <>
          <Button
            h='48px'
            variant='startCourse'
            border='2px solid #E5E5E5'
            paddingX='14px'
            w={{ base: '100%', lg: 'auto' }}
            flexGrow={{ base: 1, lg: 0 }}
            display='flex'
            alignItems='center'
            justifyContent='flex-start'
            onClick={(e) => {
              e.stopPropagation()
              onMarkAsUndone()
            }}
          >
            <Icon as={Icons8CircleCloseIcon} w='24px' h='24px' color='gray.800' marginRight='9px' />
            <Text fontSize={{ base: '.75rem', md: '1rem' }}>{contentData?.markAsNotComplete}</Text>
          </Button>
        </>
      ) : null}
    </>
  )
}
