import { LazyQueryExecFunction, useLazyQuery } from '@apollo/client'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useAuthToken } from 'src/context/auth'
import { GET_CMS_SKILLS } from 'src/graphql/queries/GET_CMS_SKILLS'
import { EventsImplementation } from 'src/hooks/Mixpanel/types'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { CmsSkill } from 'src/modules/cms/domain/Skill'
import { makeUpdateUserPreferences } from 'src/modules/iam/factory/makeUpdateUserPreferences'
import { cmsQueryContext } from 'src/services/apollo/ApolloClient'
import { cmsClient } from 'src/services/http/cms-client'
import { recommendationSystemClient } from 'src/services/http/recommendations-client'
import { QuizContentTypes } from 'src/types/Quiz'
import { useUser } from './userContext'
import { useTranslation } from 'react-i18next'

export type ContentType = {
  id: number
  title: string
  order?: number | undefined
}

export interface Skill extends CmsSkill {
  context?: string
}

export type QuizValues = {
  skills: Skill[]
  skillsSnapshot: Skill[]
  selectedSkills: Skill[]
  contentTypes: QuizContentTypes[]
  selectedContentTypes: QuizContentTypes[]
  languages: { value: string; label: string }[]
  subtitles: { value: string; label: string }[]
  noContentForSkills: string[]
}

interface Value {
  handleNextStep: () => void
  handleStepBack: () => void
  step: number
  quizValues: QuizValues
  allSkills: CmsSkill[]
  isLoadingSkills: boolean
  getAllCmsSkills: LazyQueryExecFunction<
    any,
    {
      skip: number
      limit: number
    }
  >
  updateQuiz: (field: string, value: any) => void
  updateStep: (step: number) => void
  isAlreadyRedirected: boolean
  alreadyRedirected: () => void

  resetState: () => void
  createCustomPlaylist: (paramSkills?: Skill[]) => Promise<{ success: boolean; data: any }>
  deleteCustomPlaylist: () => Promise<{ success: boolean }>
  removeContentFromPlaylist: (playlistId: string, moduleId: string, contentId: string) => Promise<{ success: boolean }>
  removeModuleFromPlaylist: (playlistId: string, moduleId: string) => Promise<{ success: boolean }>
  fetchMoreSkills: () => Promise<{ hasMore: boolean }>
  editSkills: (params: any) => Promise<any>
  isLoadingFirstRequest: boolean

  userSkills: Skill[]

  skillsToAddOnEdit: Skill[]
  skillsToRemoveOnEdit: Skill[]

  setSkillsToAddOnEdit: React.Dispatch<React.SetStateAction<Skill[]>>
  setSkillsToRemoveOnEdit: React.Dispatch<React.SetStateAction<Skill[]>>
}

interface ProviderProps {
  children: React.ReactNode[] | React.ReactNode
}

export type Content = {
  code: string
  type: string
}

type GetCmsSkillsResponse = {
  skillList: {
    items: CmsSkill[]
    total: number
  }
}

export type SelectedOptions = {
  value: string
  label: string
}

const CustomPlaylistQuizContext = createContext({} as Value)

const SKILLS_LIMIT = 50

export function CustomPlaylistQuizProvider({ children }: ProviderProps) {
  const [t] = useTranslation()
  const { track } = useMixpanel()
  const { token } = useAuthToken()
  const { user } = useUser()

  const [quizStep, setQuizStep] = useState(0)
  const [quizValues, setQuizValues] = useState<QuizValues>({
    skillsSnapshot: [] as Skill[],
    skills: [] as Skill[],
    selectedSkills: [] as Skill[],
    contentTypes: [] as QuizContentTypes[],
    selectedContentTypes: [] as QuizContentTypes[],
    languages: [{ value: 'PT', label: t('languages.PT') }],
    subtitles: [{ value: 'PT', label: t('languages.PT') }],
    noContentForSkills: [],
  })
  const [isAlreadyRedirected, setIsAlreadyRedirected] = useState(false)
  const [allSkills, setAllSkills] = useState<CmsSkill[]>([])
  const [skipCmsSkillsQuery, setSkipCmsSkillsQuery] = useState(0)
  const [totalSkills, setTotalSkills] = useState(0)
  const [isLoadingFirstRequest, setIsLoadingFirstRequest] = useState(true)

  const [userSkills, setUserSkills] = useState<Skill[]>([])

  const [skillsToAddOnEdit, setSkillsToAddOnEdit] = useState<Skill[]>([])
  const [skillsToRemoveOnEdit, setSkillsToRemoveOnEdit] = useState<Skill[]>([])

  const sortSkillsByNameAsc = (skills: CmsSkill[]): CmsSkill[] => {
    const list = new Array(...skills)
    return list.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
  }

  const getCorrectSkillName = (skill: CmsSkill) => {
    const userLanguage = user?.language ?? 'pt-BR'
    const nameToUse =
      skill.translations?.find((translation) => translation.language === userLanguage)?.name ?? skill?.name
    return {
      ...skill,
      name: nameToUse,
    }
  }

  const [getAllCmsSkills, { loading: isLoadingSkills }] = useLazyQuery(GET_CMS_SKILLS, {
    context: cmsQueryContext,
    notifyOnNetworkStatusChange: true,
    variables: {
      skip: skipCmsSkillsQuery,
      limit: SKILLS_LIMIT,
    },
    onCompleted: (data: GetCmsSkillsResponse) => {
      const skillsList = data?.skillList?.items ?? []
      const skillsListWithCorrectName = skillsList.map(getCorrectSkillName)
      const sortedSkills = sortSkillsByNameAsc(skillsListWithCorrectName)
      setAllSkills(sortedSkills)
      setTotalSkills(data?.skillList?.total ?? 0)
      setIsLoadingFirstRequest(false)
    },
  })

  const getQuizContentTypes = async () => {
    const { data, status } = await recommendationSystemClient.get('/features')
    if (status !== 200 || !data) return
    setQuizValues((prevState) => ({ ...prevState, contentTypes: data }))
  }

  useEffect(() => {
    if (token) {
      setQuizStep(0)
      getQuizContentTypes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  useEffect(() => {
    if (user && user?.skills && allSkills.length > 0) {
      setUserSkills(allSkills.filter((skill) => skill.id === user?.skills?.find((userSkill) => userSkill === skill.id)))
    }
  }, [user, allSkills])

  const fetchMoreSkills = async () => {
    if (skipCmsSkillsQuery + SKILLS_LIMIT >= totalSkills) return { hasMore: false }
    await getAllCmsSkills({
      variables: {
        skip: skipCmsSkillsQuery + SKILLS_LIMIT,
        limit: SKILLS_LIMIT,
      },
      onCompleted: (data) => {
        setAllSkills((prevState) => {
          const list = [...prevState, ...data?.skillList?.items]
          const skillsListWithCorrectName = list.map(getCorrectSkillName)
          const sortedSkills = sortSkillsByNameAsc(skillsListWithCorrectName)
          return sortedSkills
        })
      },
    })
    setSkipCmsSkillsQuery((prevState) => prevState + SKILLS_LIMIT)
    return { hasMore: true }
  }

  const alreadyRedirected = () => {
    setIsAlreadyRedirected(true)
  }

  const handleNextStep = () => {
    setQuizStep((prevState) => prevState + 1)
  }

  const handleStepBack = () => {
    setQuizStep((prevState) => prevState - 1)
  }

  const updateQuiz = useCallback((field: string, value: any) => {
    setQuizValues((prevState) => ({ ...prevState, [field]: value }))
  }, [])

  const updateStep = (step: number) => {
    track(EventsImplementation.PERSONALIZED_PLAYLIST_QUIZ_NEXT_STEP((step - 1).toString()))
    setQuizStep(step)
  }

  const parseParamsToApi = (skills: Skill[], contentTypes: QuizContentTypes[]) => {
    const skillsParsed = skills.map((item, index) => ({
      skill_id: item.id,
      order: index + 1,
      context: item.context,
    }))

    const contentTypesParsed = contentTypes.map((item, index) => ({
      feature_code: item.code,
      priority: index + 1,
    }))

    const languages = quizValues.languages.filter((item) => item.value !== 'all').map((item) => item.value)
    const subtitles = quizValues.subtitles.some((option) => option.value === 'noSubtitle')
      ? []
      : quizValues.subtitles.filter((item) => item.value !== 'noSubtitle').map((item) => item.value)

    return { skills: skillsParsed, system_features: contentTypesParsed, languages, subtitles }
  }

  const createCustomPlaylist = async (paramSkills?: Skill[]) => {
    const updateUserPreferences = makeUpdateUserPreferences()
    const { skills, system_features, languages, subtitles } = parseParamsToApi(
      paramSkills ?? quizValues?.skills ?? [],
      quizValues?.selectedContentTypes ?? [],
    )

    try {
      await updateUserPreferences.execute({ languages, subtitles })

      const response = await cmsClient.request({
        url: `/playlist/v3/generate`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: {
          skills: skills ?? [],
          system_features: system_features ?? [],
        },
      })

      return {
        success: Object.keys(response?.data?.playlist || {}).length > 0,
        data: response?.data,
      }
    } catch (err: any) {
      return {
        success: false,
        data: err?.response?.data,
      }
    }
  }

  const deleteCustomPlaylist = async () => {
    try {
      await cmsClient.request({
        url: `/playlist/custom`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return {
        success: true,
      }
    } catch {
      return {
        success: false,
      }
    }
  }

  const removeContentFromPlaylist = async (playlistId: string, moduleId: string, contentId: string) => {
    try {
      await cmsClient.request({
        url: `/playlist/${playlistId}/module/${moduleId}/content/${contentId}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return {
        success: true,
      }
    } catch {
      return {
        success: false,
      }
    }
  }

  const removeModuleFromPlaylist = async (playlistId: string, moduleId: string) => {
    try {
      await cmsClient.request({
        url: `/playlist/${playlistId}/module/${moduleId}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return {
        success: true,
      }
    } catch {
      return {
        success: false,
      }
    }
  }

  const editSkills = async (params: any) => {
    try {
      const response = await cmsClient.request({
        url: `/playlist/custom/skills`,
        data: {
          skills: params,
        },
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return response
    } catch (err) {
      return null
    }
  }

  const resetState = useCallback(() => {
    setQuizValues((prevState) => ({ ...prevState, skillsSnapshot: [] }))
  }, [])

  useEffect(() => {
    if (user) {
      const language = user?.language || 'pt-BR'
      if (language === 'es') {
        setAllSkills([])
      }
    }
  }, [user])

  return (
    <CustomPlaylistQuizContext.Provider
      value={{
        step: quizStep,
        handleNextStep,
        handleStepBack,
        quizValues,
        allSkills,
        getAllCmsSkills: getAllCmsSkills as any,
        isLoadingSkills,
        updateQuiz,
        updateStep,
        isAlreadyRedirected,
        alreadyRedirected,
        userSkills,
        resetState,
        createCustomPlaylist,
        deleteCustomPlaylist,
        fetchMoreSkills,
        isLoadingFirstRequest,
        skillsToAddOnEdit,
        skillsToRemoveOnEdit,
        setSkillsToAddOnEdit,
        setSkillsToRemoveOnEdit,
        removeContentFromPlaylist,
        removeModuleFromPlaylist,
        editSkills,
      }}
    >
      {children}
    </CustomPlaylistQuizContext.Provider>
  )
}

export const useCustomPlaylistQuiz = () => useContext(CustomPlaylistQuizContext)
