import {
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoShuffleOutline } from 'react-icons/io5'
import { useMixpanel } from 'src/hooks/Mixpanel/useMixpanel'
import { Size } from 'src/hooks/useWindowSize'
import Content from 'src/modules/cms/domain/Content'
import { Icons8CircleCloseIcon } from 'src/theme/Icons'

interface Props {
  content: Content
  toggleSwapContentDrawer: () => void
  toggleRemoveContentModal: () => void
  cardContentSize: Size
}

export const CustomPlaylistButtons: React.FC<Props> = ({
  content,
  toggleRemoveContentModal,
  toggleSwapContentDrawer,
  cardContentSize,
}) => {
  const { t } = useTranslation()
  const { EventsImplementation, track } = useMixpanel()
  const removeWord = cardContentSize.width! < 1340

  const handleOpenRemoveContentModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    track(EventsImplementation.REMOVE_LEARNING_ITEM(content.title))
    toggleRemoveContentModal()
  }

  const handleOpenSwapContentDrawer = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    track(EventsImplementation.PERSONALIZED_PLAYLIST_CLICK_TO_CHANGE_LEARNING_ITEM(content.title))
    toggleSwapContentDrawer()
  }

  return (
    <Flex gap='12px' w={{ base: '100%', lg: 'auto' }} flexWrap='wrap'>
      <Popover trigger='hover' placement='top'>
          {removeWord && (
            <PopoverContent
              borderColor='gray.800'
              backgroundColor='gray.800'
              borderRadius='20px'
              width='fit-content'
              border='none'
              zIndex='popover'
            >
              <PopoverArrow backgroundColor='gray.800' />
              <PopoverBody textAlign={'center'}>
                <Text fontSize='14px' color='white' fontWeight={700}>
                  {t('home.Custom Playlist.swap content')}
                </Text>
              </PopoverBody>
            </PopoverContent>
          )}

          <PopoverTrigger>
            <Button
              variant='startCourseDark'
              flexGrow={{ base: 1, lg: 0 }}
              onClick={handleOpenSwapContentDrawer}
              gap='12px'
              w='auto'
              display='flex'
              alignItems='center'
              justifyContent='flex-start'
            >
              <Icon w='24px' h='24px' color='white' as={IoShuffleOutline} />
              <Text fontSize={{ base: '.75rem', md: '1rem' }} color='white'>
                {t('home.Custom Playlist.swap content')}
              </Text>
            </Button>
          </PopoverTrigger>
        </Popover>

      <Popover trigger='hover' placement='top'>
        {removeWord && (
          <PopoverContent
            borderColor='gray.800'
            backgroundColor='gray.800'
            borderRadius='20px'
            width='fit-content'
            border='none'
            zIndex='popover'
          >
            <PopoverArrow backgroundColor='gray.800' />
            <PopoverBody textAlign={'center'}>
              <Text fontSize='14px' color='white' fontWeight={700}>
                {t('home.Custom Playlist.remove content')}
              </Text>
            </PopoverBody>
          </PopoverContent>
        )}

        <PopoverTrigger>
          <Button
            h='48px'
            variant='startCourse'
            border='2px solid #E5E5E5'
            onClick={handleOpenRemoveContentModal}
            flexGrow={{ base: 1, lg: 0 }}
            gap='12px'
            w='auto'
            display='flex'
            alignItems='center'
            justifyContent='flex-start'
          >
            <Icon as={Icons8CircleCloseIcon} w='24px' h='24px' color='gray.800' />
            {/* {!removeWord && <Text>{t('home.Custom Playlist.remove content')}</Text>} */}
            <Text fontSize={{ base: '.75rem', md: '1rem' }}>{t('home.Custom Playlist.remove content')}</Text>
          </Button>
        </PopoverTrigger>
      </Popover>
    </Flex>
  )
}
