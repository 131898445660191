import { Box, Modal, ModalContent, ModalOverlay } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import LearningItemBody from 'src/components/LearningItemModal/LearningItemBody'
import LearningItemHeader from 'src/components/LearningItemModal/LearningItemHeader'
import LearningItemInfoCard from 'src/components/LearningItemModal/LearningItemInfoCard'
import { LearningItemModalProvider, useLearningItemModal } from 'src/context/LearningItemModalContext'
import Content from 'src/modules/cms/domain/Content'

interface Props {
  initialLearningItem?: Content
  learningId?: string
  onClose: () => void
  isOpen: boolean
  setLearningItem?: (item: Content) => void
  moduleId?: string
  playlistId?: string
}

const LearningItemModal: React.FC<Props> = ({
  initialLearningItem,
  learningId,
  onClose,
  isOpen,
  setLearningItem,
  moduleId,
  playlistId,
}: Props) => {
  const { itemToLoad, resetItemToLoad, content, loadContent, loadContentById } = useLearningItemModal()
  const [lastPathname, setLastPathname] = useState('')

  useEffect(() => {
    setLastPathname(window.location.pathname)
  }, [isOpen])

  useEffect(() => {
    if (!itemToLoad && isOpen && initialLearningItem) {
      loadContent(initialLearningItem)
    }

    if (!itemToLoad && isOpen && learningId) {
      loadContentById(learningId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  const getLastPathname = () => {
    const isLasPathNameCourse = lastPathname.indexOf('content') !== -1
    if (isLasPathNameCourse) return '/home'
    return lastPathname
  }

  const removeLearningItemURL = () => {
    window.history.pushState(null, '', getLastPathname())
  }

  const onModalClose = () => {
    removeLearningItemURL()
    resetItemToLoad()
    onClose()
  }

  return (
    <>
      <Modal onClose={onModalClose} isOpen={isOpen} autoFocus={false} scrollBehavior='outside'>
        <ModalOverlay>
          <ModalContent borderRadius='30px' w={{ base: '90%', md: '80%' }} maxW='560px'>
            <Box padding={{ base: '48px 24px', md: '60px 48px 48px 48px' }} position='relative'>
              <LearningItemHeader onModalClose={onModalClose} content={content} />

              {content ? (
                <>
                  <LearningItemBody
                    onModalClose={onModalClose}
                    initialLearningItem={initialLearningItem!}
                    content={content}
                    setLearningItem={setLearningItem}
                    playlistId={playlistId}
                    moduleId={moduleId}
                  />
                  <LearningItemInfoCard content={content} />
                </>
              ) : (
                <></>
              )}
            </Box>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  )
}

const LearningItemModalWithContext: React.FC<Props> = ({
  initialLearningItem,
  learningId,
  onClose,
  isOpen,
  setLearningItem,
  moduleId,
  playlistId,
}: Props) => (
  <LearningItemModalProvider>
    <LearningItemModal
      initialLearningItem={initialLearningItem}
      learningId={learningId}
      onClose={onClose}
      isOpen={isOpen}
      setLearningItem={setLearningItem}
      moduleId={moduleId}
      playlistId={playlistId}
    />
  </LearningItemModalProvider>
)

export default LearningItemModalWithContext
