import { Box, Flex, Heading, Select } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useQuiz } from 'src/context/QuizContext'
import useRegisterUserEvent from 'src/hooks/useRegisterUserEvent'
import ButtonsFooter from './ButtonsFooter'
import StepHeader from './StepHeader'

interface Props {
  onContinueButtonClick: () => void
  onBackButtonClick: () => void
  onSkipButtonClick: () => void
}

const StepSeven: React.FC<Props> = ({ onContinueButtonClick, onBackButtonClick, onSkipButtonClick }: Props) => {
  const { quizResults, updateQuiz, sendMutationQuiz } = useQuiz()
  const [t] = useTranslation()
  const registerUserEvent = useRegisterUserEvent()

  const isButtonDisabled = !quizResults.dedicationTime || !quizResults.deadline || quizResults.languages.length === 0

  const languages = [
    {
      value: 'pt-BR',
      label: t('quiz.stepSeven.language.pt-BR'),
    },
    {
      value: 'es',
      label: t('quiz.stepSeven.language.es'),
    },
    {
      value: 'en',
      label: t('quiz.stepSeven.language.en-US'),
    },
  ]

  const handleLanguageChange = (value: string) => {
    const isLanguageAlreadySelected = quizResults.languages.some((item) => item === value)

    const languages = (() => {
      return isLanguageAlreadySelected
        ? quizResults.languages.filter((item) => item !== value)
        : [...quizResults.languages, value]
    })()

    updateQuiz('languages', languages)
  }

  const handleNextStep = () => {
    sendMutationQuiz()
    onContinueButtonClick()
    registerUserEvent('quiz-weekly-time', [quizResults.dedicationTime!])
    registerUserEvent('quiz-language', quizResults.languages || [])
  }

  return (
    <>
      <StepHeader step={7} />
      <Box flex='1'>
        <Heading size='xs'>{t('quiz.stepSeven.planExecution')}</Heading>
        <Box>
          <Heading size='xs'>{t('quiz.stepSeven.dedicationTime')}</Heading>
          <Select
            data-type='text'
            h='40px'
            mt='16px'
            bgColor='white'
            border='2px solid'
            borderColor='gray.70'
            color={!quizResults.dedicationTime ? 'gray.400' : 'gray.800'}
            value={quizResults.dedicationTime}
            onChange={(e) => updateQuiz('dedicationTime', e.target.value)}
            placeholder={t('quiz.chooseAnOption')}
          >
            <option value='ONETOFIVE'>{t('quiz.stepSeven.oneToFive')}</option>
            <option value='FIVETOTEN'>{t('quiz.stepSeven.fiveToTen')}</option>
            <option value='MORETHANTEN'>{t('quiz.stepSeven.moreThanTen')}</option>
          </Select>
        </Box>
        <Box mt='32px'>
          <Heading size='xs'>{t('quiz.stepSeven.goalsDeadline')}</Heading>
          <Select
            data-type='text'
            h='40px'
            mt='16px'
            bgColor='white'
            border='2px solid'
            borderColor='gray.70'
            color={!quizResults.deadline ? 'gray.400' : 'gray.800'}
            value={quizResults.deadline}
            onChange={(e) => updateQuiz('deadline', Number(e.target.value))}
            placeholder={t('quiz.chooseAnOption')}
          >
            <option value='1'>{t('quiz.stepSeven.oneMonth')}</option>
            <option value='3'>{t('quiz.stepSeven.threeMonths')}</option>
            <option value='5'>{t('quiz.stepSeven.fiveMonths')}</option>
            <option value='12'>{t('quiz.stepSeven.oneYear')}</option>
          </Select>
        </Box>
        <Box mt='32px'>
          <Heading size='xs'>{t('quiz.stepSeven.preferredLanguages')}</Heading>
          <Flex gap='12px' mt='16px'>
            {languages.map((item) => (
              <Flex
                key={item.value}
                border='2px solid'
                borderColor={quizResults.languages.includes(item.value) ? 'primaryColors.650' : 'gray.70'}
                bgColor={quizResults.languages.includes(item.value) ? 'primaryColors.45' : ''}
                borderRadius='18px'
                padding='0 15px'
                height='34px'
                alignItems='center'
                fontWeight='600'
                cursor='pointer'
                onClick={() => handleLanguageChange(item.value)}
              >
                {item.label}
              </Flex>
            ))}
          </Flex>
        </Box>
      </Box>
      <ButtonsFooter
        step={7}
        onFinishButtonClick={handleNextStep}
        onSkipButtonClick={onSkipButtonClick}
        onBackButtonClick={onBackButtonClick}
        disabledButton={isButtonDisabled}
      />
    </>
  )
}

export default StepSeven
