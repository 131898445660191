import { Box, Flex, Text } from '@chakra-ui/react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDashboard } from 'src/context-admin/useDashboard'
import { useTeams } from 'src/context-admin/useTeams'
import { orderTeams } from '../../utils'
import AreaSelect from './components/AreaSelect'
import OrderSelect from './components/OrderSelect'
import PermissionSelect from './components/PermissionSelect'
import SearchInput from './components/SearchInput'
import TeamSelect from './components/TeamSelect'

type Props = {
  team: string
  area: string
  order: string
  handleAreaChange: (area: string) => void
  handleSearchChange: (search: string) => void
  handleTeamChange: (team: string) => void
  handleOrderChange: (order: string) => void
}

const Filters: React.FC<Props> = ({
  area,
  team,
  order,
  handleAreaChange,
  handleSearchChange,
  handleTeamChange,
  handleOrderChange,
}) => {
  const [t] = useTranslation()
  const { areas } = useDashboard()
  const { teams } = useTeams()

  const orderedTeams = useMemo(() => orderTeams(teams, 'NAME_A_Z'), [teams])

  return (
    <Flex gap='24px' w='100%' align='flex-end'>
      <SearchInput
        title='Colaborador'
        placeholder='Busque por um colaborador'
        handleSearchChange={handleSearchChange}
      />

      <Box w='100%' mt='8px'>
        <Text color='#313E4C' fontSize='16px' fontWeight={700} mb='4px'>
          {t('areas')}
        </Text>
        <Flex w='100%' gridGap='8px'>
          <AreaSelect area={area} areas={areas} handleAreaChange={handleAreaChange} />
        </Flex>
      </Box>

      <Box w='100%' mt='8px'>
        <Text color='#313E4C' fontSize='16px' fontWeight={700} mb='4px'>
          {t('othersfilters')}
        </Text>
        <Flex gridGap='8px'>
          <OrderSelect order={order} handleOrderChange={handleOrderChange} />
          {teams && <TeamSelect team={team} teams={orderedTeams} handleTeamChange={handleTeamChange} />}
          <PermissionSelect />
        </Flex>
      </Box>
    </Flex>
  )
}

export default Filters
