import { Box, Flex } from '@chakra-ui/layout'
import { Icon, Image, Skeleton, Stack, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoBusinessSharp, IoChevronBack, IoChevronForward } from 'react-icons/io5'
import { useLocation } from 'react-router-dom'
import { useUser } from 'src/context/userContext'
import { AnalyticsIcon } from 'src/theme-admin/Icons'
// import '../../i18n'
import { CorporateUniversityIcon, DashboardIcon, HelpIcon } from '../../theme/Icons'
import MenuLink from './MenuLink'

const Sidemenu: React.FC = () => {
  const location = useLocation()
  const [t] = useTranslation()
  const { loading, user } = useUser()

  const [isFixedMenuOpen, setIsFixedMenuOpen] = useState(false)
  const [isHoverMenuOpen, setIsHoverMenuOpen] = useState(false)
  const [isDashboardsOpen, setIsDashboardsOpen] = useState(false)

  const isDashboardsActive = location.pathname.indexOf('dashboard') !== -1

  const isMenuOpen = isHoverMenuOpen || isFixedMenuOpen

  const isUserManager = user?.roles?.includes('MANAGER') && !user?.roles?.includes('ADMIN')
  const isUserAdmin = user?.roles?.includes('ADMIN')
  const isUserBackoffice = user?.roles?.includes('BACKOFFICE')

  if (loading) return <Skeleton height='100%' background='gray.300' w='79px' />

  let menuDashboardItems = [
    {
      title: 'Trilha Personalizadas',
      url: '/admin/dashboard',
    },
  ]

  /*
  if (role === 'ADMIN')
    menuDashboardItems.push({
      title: 'Cursos',
      url: '/admin/dashboard',
    })
  */

  return (
    <>
      {isHoverMenuOpen && <Box minW='78px' h='100%' display={{ base: 'none', lg: 'flex' }} />}

      <Flex
        className='side-menu'
        background={isUserManager ? '#087E45' : '#313E4C'}
        height='100%'
        flexDirection='column'
        paddingTop='25px'
        minW={isMenuOpen ? '302px' : 'auto'}
        position={isHoverMenuOpen ? 'absolute' : 'relative'}
        display={['none', 'none', 'none', 'flex', 'flex']}
        paddingRight={isMenuOpen ? '17px' : 'unset'}
        zIndex='8'
        filter={isHoverMenuOpen ? 'drop-shadow(0px 5.43953px 100.631px rgba(0, 0, 0, 0.35))' : ''}
        onMouseLeave={() => {
          setIsHoverMenuOpen(false)
        }}
      >
        {!isMenuOpen ? (
          <Flex
            position='absolute'
            top='0'
            left='0'
            w='78px'
            h='110px'
            onMouseEnter={() => {
              setIsHoverMenuOpen(true)
            }}
          />
        ) : null}
        <Flex marginBottom='40px' marginLeft='22px' gap='17px' alignItems='center'>
          {!isMenuOpen ? (
            <Image src='/assets/images/ej-logo-reduced-white.svg' w='32px' />
          ) : (
            <Image src='/assets/images/ej-logo-white.svg' h='32px' />
          )}
        </Flex>

        <Flex
          data-variant='unstyled'
          position='absolute'
          top='61px'
          right='-14px'
          w='25px'
          h='25px'
          background='white'
          border='1px solid #E5E5E5'
          borderRadius='8px'
          display='flex'
          alignItems='center'
          justifyContent='center'
          cursor='pointer'
          _focus={{ outline: 'none' }}
          onClick={() => {
            setIsHoverMenuOpen(false)
            setIsFixedMenuOpen(!isFixedMenuOpen)
          }}
        >
          <Icon w='12px' h='12px' as={isFixedMenuOpen ? IoChevronBack : IoChevronForward} />
        </Flex>

        {/** TOP PART - Links */}
        <Flex
          width='100%'
          height='100%'
          flexDirection='column'
          justifyContent='space-between'
          overflowY='auto'
          css={{
            '&::-webkit-scrollbar': {
              width: '0px',
            },
            '&::-webkit-scrollbar-track': {
              background: 'transparent',
            },
          }}
        >
          <Stack>
            {isUserAdmin && (
              <MenuLink href='/admin/home' icon={DashboardIcon} isMenuOpen={isMenuOpen} label={t('common.Home')}>
                {isMenuOpen ? (
                  <Text fontFamily='Poppins' color='#F2F2F2'>
                    {t('common.Home')}
                  </Text>
                ) : null}
              </MenuLink>
            )}

            <MenuLink href='/admin/dashboard' icon={AnalyticsIcon} isMenuOpen={isMenuOpen} label={'Dashboard'}>
              {isMenuOpen ? (
                <Text fontFamily='Poppins' color='#F2F2F2'>
                  Dashboard
                </Text>
              ) : null}
            </MenuLink>

            {/* <DashboardLink
              isMenuOpen={isMenuOpen}
              isOpen={isDashboardsOpen}
              active={isDashboardsActive}
              onClick={() => setIsDashboardsOpen(!isDashboardsOpen)}
            /> */}

            {/* {isDashboardsOpen && isMenuOpen ? (
              <MenuDashboards isMenuActive={isDashboardsActive} items={menuDashboardItems} />
            ) : null} */}

            {(isUserAdmin || isUserBackoffice) && (
              <MenuLink
                href='/admin/cms'
                icon={CorporateUniversityIcon}
                isMenuOpen={isMenuOpen}
                label={t('admin.corporateUniversity.title')}
              >
                {isMenuOpen ? (
                  <Text fontFamily='Poppins' color='#F2F2F2' fontSize='1rem'>
                    {t('admin.corporateUniversity.title')}
                  </Text>
                ) : null}
              </MenuLink>
            )}

            {isUserBackoffice && (
              <MenuLink icon={IoBusinessSharp} href='/admin/backoffice' isMenuOpen={isMenuOpen} label={'Backoffice'}>
                {isMenuOpen ? (
                  <Text fontFamily='Poppins' color='#F2F2F2'>
                    Backoffice
                  </Text>
                ) : null}
              </MenuLink>
            )}

            <MenuLink
              href='https://educationjourney.zendesk.com'
              icon={HelpIcon}
              isExternal={true}
              isMenuOpen={isMenuOpen}
              label={t('common.Help')}
            >
              {isMenuOpen ? (
                <Text fontFamily='Poppins' color='#F2F2F2'>
                  {t('common.Help')}
                </Text>
              ) : null}
            </MenuLink>
          </Stack>

          {!isMenuOpen ? (
            <Flex
              flex='1'
              onMouseEnter={() => {
                setIsHoverMenuOpen(true)
              }}
            />
          ) : null}
        </Flex>
      </Flex>
    </>
  )
}

export default Sidemenu
