import { Box, Button, Flex, Spinner, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { useDashboardReports } from '../../hooks/useDashboardReports'
import { SelectAreas } from '../SelectAreas'
import { SelectSubsidiaries } from '../SelectSubsidiaries'
import { SelectUsers } from '../SelectUsers'
import { SelectDateFilter } from '../SelectDateFilter'
import { SelectPlaylists } from '../SelectPlaylists'
import { useTranslation } from 'react-i18next'

export type Option = {
  value: any;
  label: string;
}

export function ReportContent() {
  const [t] = useTranslation()
  const { reportUrl, generateReportUrl, activeTab, isLoading, iframeLoading, setIframeLoading } = useDashboardReports()
  const [usersSelected, setUsersSelected] = useState<Option[]>([])
  const [subsidiariesSelected, setSubsidiariesSelected] = useState<Option[]>([])
  const [areasSelected, setAreasSelected] = useState<Option[]>([])
  const [playlistSelected, setPlaylistSelected] = useState<Option>()
  const [dateFilterSelected, setDateFilterSelected] = useState<Option>()

  return (
    <Flex flexDir='column'>
      <Flex flexWrap="wrap" w="100%" gap={4}>
        <Flex flexWrap="wrap" gap={4}>
          <SelectAreas
            areasSelected={areasSelected}
            setAreasSelected={(value) => {
              setAreasSelected(value)
              setUsersSelected([])
            }}
          />
          <SelectSubsidiaries
            subsidiariesSelected={subsidiariesSelected}
            setSubsidiariesSelected={(value) => {
              setSubsidiariesSelected(value)
              setUsersSelected([])
            }}
          />
          <SelectUsers
            usersSelected={usersSelected}
            setUsersSelected={setUsersSelected}
            subsidiariesSelected={subsidiariesSelected}
            areasSelected={areasSelected}
          />

          {
            activeTab === 'featured' && (
              <SelectPlaylists
                playlistSelected={playlistSelected}
                setPlaylistSelected={setPlaylistSelected}
              />
            )
          }

          <SelectDateFilter
            dateFilterSelected={dateFilterSelected}
            setDateFilterSelected={setDateFilterSelected}
          />
        </Flex>

        <Button
          flexGrow={{ base: 1, lg: 0 }}
          w="150px"
          minW="150px"
          maxW="150px"
          variant='startCourseDark'
          onClick={() => {
            generateReportUrl({
              type: activeTab,
              area: areasSelected.map((area) => area.value),
              users: usersSelected.map((user) => user.value),
              subsidiaries: subsidiariesSelected.map((subsidiary) => subsidiary.value),
              date: dateFilterSelected?.value,
              playlistId: activeTab === 'featured' ? playlistSelected?.value : undefined,
            })
          }}
          isLoading={isLoading || iframeLoading}
        >
          <Text fontSize={{ base: '.75rem', md: '1rem' }} color='white'>
            {t('admin.reports.generate_report')}
          </Text>
        </Button>
      </Flex>
      {isLoading ? (
        <Flex h='50vh' align='center' justify='center'>
          <Spinner />
        </Flex>
      ) : reportUrl ? <Box
        mt={6}
        as="iframe"
        h="calc(100vh - 200px)"
        title='Report'
        src={reportUrl}
        width='100%'
        onLoad={() => {
          setIframeLoading(false)
        }}
        style={{ border: 'none' }}
        frameBorder="0"
        allowTransparency={true}
      /> : null}
    </Flex>
  )
}
