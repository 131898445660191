import { Box, Button, Icon } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { IoCheckmarkCircle } from 'react-icons/io5'
import { useAddContentDrawer } from 'src/context/CustomPlaylist/AddContentDrawerContext'
import Content from 'src/modules/cms/domain/Content'

type Props = {
  content: Content
}

const SelectContent: React.FC<Props> = ({ content }) => {
  const [t] = useTranslation()
  const { newContents, setNewContents } = useAddContentDrawer()
  const itemExists = newContents.some((item) => item.id === content.id)

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()

    if (itemExists) {
      // @ts-ignore
      setNewContents((prevState) => prevState.filter((item) => item.id !== content.id))
    } else {
      // @ts-ignore
      setNewContents((prevState) => [...prevState, content])
    }
  }

  return (
    <Box ml={{ base: '0px' }} w='auto'>
      <Button
        variant='startCourseDark'
        height='48px'
        padding='12px 24px'
        bg={itemExists ? 'primaryColors.650' : 'gray.800'}
        _hover={{ bg: itemExists ? 'primaryColors.650' : 'gray.800' }}
        onClick={handleClick}
        fontSize={{ base: '.75rem', md: '1rem' }}
        display='flex'
        w='100%'
        flexGrow={1}
      >
        {itemExists && <Icon w='30px' h='30px' color='white' marginRight='8px' as={IoCheckmarkCircle} />}
        {itemExists ? t('home.Custom Playlist.Selected') : t('home.Custom Playlist.Select')}
      </Button>
    </Box>
  )
}

export default SelectContent
